import style from './Main.module.scss';
import titleSvg from './assets/title.svg';
import avatarImg from './assets/avatar.jpg';
import { useCopyToClipboard } from 'usehooks-ts';

const Main = () => {
  const [copiedText, copy] = useCopyToClipboard();

  return (
    <div className={style.main}>
      <div className='__container'>
        <div className={style.mainBody}>
          <div className={style.mainBody__title}>
            <img src={titleSvg} alt='' />
          </div>
          <div className={style.mainBody__avatar}>
            <img src={avatarImg} alt='' />
          </div>
          <div className={style.mainBody__text}>
            $DUCK is the cutest dog on Solana, on a <br /> mission to be worth
            billyons and billyons!
          </div>
          <div className={style.mainBody__address}>
            <span>3H4ws9Dcbqc4RiNKZjNiAaaBtEf12Dn6C9raqMRmWj83</span>
            <button
              onClick={() => {
                copy('3H4ws9Dcbqc4RiNKZjNiAaaBtEf12Dn6C9raqMRmWj83');
              }}
            >
              <svg
                width='37'
                height='37'
                viewBox='0 0 37 37'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M5.01041 11.9479C5.01041 8.75504 7.59876 6.16669 10.7917 6.16669H15.0514C16.7391 6.16669 18.3425 6.90416 19.4408 8.18555L21.3443 10.4063H18.2985L17.685 9.6905C17.026 8.92167 16.064 8.47919 15.0514 8.47919H10.7917C8.87592 8.47919 7.32291 10.0322 7.32291 11.9479V21.1979C7.32291 23.1137 8.87592 24.6667 10.7917 24.6667H12.3333V26.9792H10.7917C7.59876 26.9792 5.01041 24.3908 5.01041 21.1979V11.9479Z'
                  fill='#FFA800'
                />
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M20.0417 12.3334C16.8488 12.3334 14.2604 14.9217 14.2604 18.1146V27.3646C14.2604 30.5575 16.8488 33.1459 20.0417 33.1459H26.2083C29.4012 33.1459 31.9896 30.5575 31.9896 27.3646V20.3394C31.9896 18.9593 31.4959 17.6248 30.5978 16.577L28.6908 14.3522C27.5925 13.0708 25.9891 12.3334 24.3014 12.3334H20.0417ZM16.5729 18.1146C16.5729 16.1989 18.1259 14.6459 20.0417 14.6459H24.3014C25.314 14.6459 26.276 15.0883 26.935 15.8572L28.842 18.082C29.3809 18.7106 29.6771 19.5114 29.6771 20.3394V27.3646C29.6771 29.2803 28.1241 30.8334 26.2083 30.8334H20.0417C18.1259 30.8334 16.5729 29.2803 16.5729 27.3646V18.1146Z'
                  fill='#FFA800'
                />
              </svg>
            </button>
          </div>
          <div className={style.mainBody__links}>
            <a
              href='https://x.com/solanaquack?s=21'
              target='_blank'
              rel='noreferrer'
            >
              <svg
                width='43'
                height='43'
                viewBox='0 0 43 43'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M32.8413 3.69421H38.7311L25.8637 18.4008L41.0012 38.4131H29.1487L19.8654 26.2757L9.24315 38.4131H3.34984L17.1128 22.6828L2.59137 3.69421H14.7448L23.1361 14.7882L32.8413 3.69421ZM30.7742 34.8878H34.0378L12.9714 7.03435H9.46927L30.7742 34.8878Z'
                  fill='black'
                />
              </svg>
              Follow X
            </a>
            <a href='https://t.me/QuakDuckSol' target='_blank' rel='noreferrer'>
              <svg
                width='43'
                height='43'
                viewBox='0 0 43 43'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M42.4973 21.3209C42.4973 32.8917 33.1173 42.2717 21.5465 42.2717C9.97569 42.2717 0.595703 32.8917 0.595703 21.3209C0.595703 9.75011 9.97569 0.370117 21.5465 0.370117C33.1173 0.370117 42.4973 9.75011 42.4973 21.3209ZM22.2973 15.8369C20.2595 16.6845 16.1868 18.4388 10.0792 21.0997C9.08746 21.4941 8.56793 21.88 8.52063 22.2572C8.4407 22.8948 9.23915 23.1459 10.3264 23.4878C10.4743 23.5343 10.6276 23.5825 10.7847 23.6335C11.8544 23.9813 13.2933 24.3881 14.0414 24.4042C14.7199 24.4189 15.4773 24.1391 16.3134 23.565C22.0199 19.7129 24.9656 17.7659 25.1506 17.7239C25.281 17.6943 25.4618 17.6571 25.5843 17.766C25.7069 17.8749 25.6948 18.0811 25.6818 18.1364C25.6027 18.4736 22.4685 21.3874 20.8466 22.8953C20.341 23.3654 19.9823 23.6989 19.909 23.775C19.7447 23.9456 19.5773 24.107 19.4165 24.2621C18.4227 25.2201 17.6774 25.9385 19.4577 27.1117C20.3133 27.6755 20.9979 28.1417 21.6809 28.6068C22.4267 29.1147 23.1707 29.6214 24.1332 30.2523C24.3785 30.4131 24.6127 30.5801 24.8408 30.7427C25.7088 31.3615 26.4887 31.9175 27.4521 31.8288C28.012 31.7773 28.5903 31.2509 28.8839 29.6809C29.578 25.9705 30.9423 17.9312 31.2576 14.6184C31.2852 14.3281 31.2504 13.9567 31.2225 13.7936C31.1946 13.6305 31.1363 13.3982 30.9244 13.2262C30.6733 13.0225 30.2858 12.9796 30.1125 12.9826C29.3245 12.9965 28.1155 13.4169 22.2973 15.8369Z'
                  fill='black'
                />
              </svg>
              Follow TG
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
